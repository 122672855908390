@page { margin: 0 }
body { margin: 0; position: relative }
.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
}

.height-min {
    height: 305mm
}

/** Paper sizes **/
body.A4               .sheet { width: 210mm }

/** For screen preview **/
@media screen {
    body { background: white }
    .sheet {
        background: white;
        box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
        margin: 1mm auto;
    }
}

/** Fix for Chrome issue #273306 **/
@media print {
    body.A4 { width: 210mm }
}
